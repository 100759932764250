import { Helmet } from "react-helmet-async"
import "./layout.css"
import { SegmentPageTracker } from "../lib/SegmentPageTracker"

export const Head = ({ useKameleoon = false }) => {
  return (
    <>
      <SegmentPageTracker />
      <Helmet>
        <link rel="preconnect" href="https://cerebral.prismic.io" />
        <link rel="preconnect" href="https://data.kameleoon.io" />
        <link rel="preconnect" href="https://utt.impactcdn.com" />
        <link rel="preconnect" href="https://fast.ssqt.io" />
        <link rel="preconnect" href="https://cdn.segment.com" />
        <link rel="preconnect" href="https://3zutpy0bjn.kameleoon.eu" />
        <link rel="preconnect" href="https://cdn.heapanalytics.com" />
        <link rel="preconnect" href="https://images.prismic.io" crossorigin />
        <link rel="dns-prefetch" href="https://images.prismic.io" />
        <link
          rel="preconnect"
          href="https://rum.browser-intake-datadoghq.com"
        />
        <script type="text/javascript">{`
                  if (sessionStorage.getItem("gatsby-reload-compilation-hash-match") === "1" && !window.location.href.includes("?nocache=")) {
                    window.location.href =
                      window.location.href.split("?")[0] + "?nocache=" + new Date().getTime()
                  }
              `}</script>
      </Helmet>
      <Helmet>
        {process.env.GATSBY_SEGMENT_ACTIVE_ENV?.includes("development") ? (
          <script async>{`
                  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.GATSBY_DEV_SEGMENT_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
                  analytics.load("${process.env.GATSBY_DEV_SEGMENT_KEY}");
                  }}();
              `}</script>
        ) : (
          <script async>{`
                  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.GATSBY_PROD_SEGMENT_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
                  analytics.load("${process.env.GATSBY_PROD_SEGMENT_KEY}");
                  }}();
              `}</script>
        )}
        <script async type="text/javascript">
          {`(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A2435171-ef85-4e75-a435-a531a42fbce31.js','script','ire',document,window);`}
        </script>
      </Helmet>

      {useKameleoon && (
        <Helmet>
          <script async>
            {`
                var kameleoonLoadingTimeout = 2000;
                window.kameleoonQueue = window.kameleoonQueue || [];
                window.kameleoonStartLoadTime = new Date().getTime();
                if (! document.getElementById("kameleoonLoadingStyleSheet") && ! window.kameleoonDisplayPageTimeOut)
                {
                    var kameleoonS = document.getElementsByTagName("script")[0];
                    window.kameleoonDisplayPage = function(fromEngine)
                    {
                        if (!fromEngine)
                        {
                            window.kameleoonTimeout = true;
                        }
                    };
                    window.kameleoonDisplayPageTimeOut = window.setTimeout(window.kameleoonDisplayPage, kameleoonLoadingTimeout);
                }
              `}
          </script>
          <script async type="text/javascript">{`
                // Change the value of this URL to point to your own URL, where the iFrame is hosted
                window.kameleoonIframeURL = '${process.env.GATSBY_PATH_FILE_IFRAME_KAMELEOON}';
                window.kameleoonLightIframe = false;
                var kameleoonIframeOriginElement = document.createElement("a");
                kameleoonIframeOriginElement.href = kameleoonIframeURL;
                window.kameleoonIframeOrigin = kameleoonIframeOriginElement.origin || (kameleoonIframeOriginElement.protocol + "//" + kameleoonIframeOriginElement.hostname);
                if (location.href.indexOf(window.kameleoonIframeOrigin) != 0)
                {
                    window.kameleoonLightIframe = true;
                    var kameleoonProcessMessageEvent = function(event)
                    {
                        if (window.kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0,9) == "Kameleoon")
                        {
                            window.removeEventListener("message", kameleoonProcessMessageEvent);
                            window.kameleoonExternalIFrameLoaded = true;
                            if (window.Kameleoon)
                            {
                                eval(event.data);
                                Kameleoon.Analyst.load();
                            }
                            else
                            {
                                window.kameleoonExternalIFrameLoadedData = event.data;
                            }
                        }
                    };
                    if (window.addEventListener)
                    {
                        window.addEventListener("message", kameleoonProcessMessageEvent, false);
                    }
                    var iframeNode = document.createElement("iframe");
                    iframeNode.src = kameleoonIframeURL;
                    iframeNode.id = "kameleoonExternalIframe";
                    iframeNode.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
                    document.head.appendChild(iframeNode);
                }
                `}</script>
          <script
            type="text/javascript"
            src={`//${process.env.GATSBY_SITE_CODE_KAMELEOON}.kameleoon.eu/kameleoon.js`}
            async
          />
        </Helmet>
      )}
    </>
  )
}

export default Head
